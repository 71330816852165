// reactstrap components
import { AuthContext } from "context/auth-context/auth.context";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "reactstrap";
// import VideoJS from "components/video/VideoPlayer";

const HeaderComponenent = () => {
  const { t, i18n } = useTranslation();
  const { currentUserFirebase } = useContext(AuthContext);
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "https://hopelapp-s3-production.s3.eu-west-3.amazonaws.com/clients/66131037a7d0a49c2c8cfafd/videos/home-video.mp4",
        type: "video/mp4",
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <div className="header-1">
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/header-1.png") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <Row>
              {/* <Col md="6">
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
              </Col> */}
              <Col className="ml-auto" md="12">
                <h2 className="title">{t("header1.title")}</h2>
                <h4 className="description">
                  <strong>{t("header1.description")}</strong>
                </h4>
                <br />
                {!currentUserFirebase || isEmpty(currentUserFirebase) ? (
                  <Button color="danger" href="/authentication/sign-up">
                    <i className="nc-icon nc-cart-simple mr-1" />
                    {t("header1.orderButton")}
                  </Button>
                ) : (
                  <Button color="danger" href="/list-trainings" target="_blank">
                    <i className="nc-icon nc-cart-simple mr-1" />
                    {t("header1.startNow")}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default HeaderComponenent;
