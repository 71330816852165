// reactstrap components
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

function SectionTestimonials({ isDark }) {
  const { t, i18n } = useTranslation();

  const data = [
    {
      name: "Djiba MILIMONO, Guinee",
      description: `JEUFZONE, au-dela de la formation est ce qu'il faut aujourd'hui a la jeunesse
                    africaine pour pouvoir se re inventer, aussi renouer avec le passe de nos
                    parents, mais e galement se projeter dans l'avenir.`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
    {
      name: "Ibrahima TRAORE , France",
      description: `J’ai suivi la formation avec l’objectif d’avoir des re ponses a mes questions pour
des projets futurs et je n’ai pas e te de çu.
Il faudrait se rapprocher de la terre afin d’atteindre l’autosuffisance
alimentaire en Afrique`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
    {
      name: "Mariam SALL, Sénégal",
      description: `Nous sommes des africains et avons le devoir de de velopper notre continent.
Personne ne le fera a notre place.
Je suis convaincue que l’Afrique peut nourrir l’Afrique.
Cette formation permet de connaî tre les tenants et aboutissants du secteur
agroalimentaire et de se lancer en toute confiance.`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
    {
      name: "Joachim TRIVAL, Ile Saint-Martin",
      description: `Je ressors satisfait de la formation et le re sultat est au-dessus de mes attentes.
La formation est de qualite .
N’he sitez pas a vous lancer`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
    {
      name: "Elie MBEKI, RDC",
      description: `Il est important de se former avant de se lancer en Business car si on ne le fait
pas, on commence mal. Voulant me lancer dans l’agorbusiness, j’ai voulu me
former pour de buter efficacement et durablement. Et j’ai pu acque rir les
notions dont j’avais besoin en un laps de temps.`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
    {
      name: "Rouguy DIALLO, Sénégal",
      description: `Il est important de se former avant de se lancer en Business car si on ne le fait
pas, on commence mal. Voulant me lancer dans l’agorbusiness, j’ai voulu me
former pour de buter efficacement et durablement. Et j’ai pu acque rir les
notions dont j’avais besoin en un laps de temps.`,
      start: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️",
      image: null,
    },
  ];
  return (
    <>
      <div
        className="section section-testimonials cd-section"
        id="testimonials"
      >
        {/* ********* TESTIMONIALS 4 ********* */}
        {/*<div className="section text-center landing-section">*/}
        <Container>
          <Row style={{ marginBottom: "5%" }}>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">{t("testimonials.title")}</h2>
            </Col>
          </Row>
          <Row style={{ marginTop: "5%" }}>
            {data.map((row) => (
              <Col md="4">
                <Card className="card-profile card-plain">
                  {row.image && (
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                        />
                      </a>
                    </div>
                  )}
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">{row.name}</CardTitle>
                        <h6 className="card-category text-muted">
                          {row.start}
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      {row.description}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        {/*</div>*/}
        {/* ********* END TESTIMONIALS 4 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials;
