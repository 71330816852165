import { useQuery } from "@tanstack/react-query";

import { getAllSubCategory } from "services/sub-category.service";

export enum KeyQuerySubCategory {
  getAllSubCategory = "getAllSubCategory",
}

export const useGetAllSubCategory = () => {
  return useQuery({
    queryKey: [KeyQuerySubCategory.getAllSubCategory],
    queryFn: () => getAllSubCategory(),
  });
};
