import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-kit.scss";
import "config/firebase.config";
import { AuthContext } from "context/auth-context/auth.context";
import RequireAuth from "context/auth-context/require-auth.provider";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ResetPassword from "views/auth/ResetPassword";
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import VerifyEmail from "views/auth/VerifyEmail";
import Home from "views/home/home";
import Lesson from "views/lesson/Lesson";
import ListTrainings from "views/list-trainings/ListTrainings";
import Purchase from "views/purchase/Purchase";

const App = () => {
  const { currentUserFirebase } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      currentUserFirebase?.emailVerified &&
      window.location.pathname.startsWith("/authentication")
    ) {
      navigate("/");
    }

    if (
      currentUserFirebase &&
      !currentUserFirebase?.emailVerified &&
      (window.location.pathname.endsWith("/sign-in") ||
        window.location.pathname.endsWith("/sign-up"))
    ) {
      navigate("/authentication/verify-email");
    }
  }, [currentUserFirebase, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/authentication/sign-in" element={<SignIn />} />
      <Route
        path="/authentication/reset-password"
        element={<ResetPassword />}
      />
      <Route path="/authentication/sign-up" element={<SignUp />} />
      <Route path="/authentication/verify-email" element={<VerifyEmail />} />
      <Route path="/list-trainings" element={<ListTrainings />} />
      <Route
        path="/lesson/:lessonId"
        element={
          <RequireAuth>
            <Lesson />
          </RequireAuth>
        }
      />
      <Route path="/purchase" element={<Purchase />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
