import { auth } from "config/firebase.config";
import { User } from "firebase/auth";
import { ReactNode, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "services/user.service";
import { SignOutUser, userStateListener } from "services/firebase.service";
import { IUser } from "types/user.type";

interface Props {
  children?: ReactNode;
}

interface CustomUser extends User {
  accessToken?: string;
}

export const AuthContext = createContext({
  currentUserFirebase: {} as CustomUser | null,
  setCurrentUserFirebase: (_user: CustomUser) => {},
  currentUser: {} as IUser | null,
  setCurrentUser: (_admin: IUser) => {},
  clientId: "" as string | null,
  setClientId: (_clientId: string) => {},
  signOut: () => {},
  isLoading: true,
});

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [currentUserFirebase, setCurrentUserFirebase] =
    useState<CustomUser | null>(null);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Ajoutez une state isLoading

  const fetchAdmin = async (uid: string) => {
    try {
      if (currentUser) return;

      const response = await getUser(uid);

      setCurrentUser(response);
    } catch (error) {
      console.error("Error when retrieving the role :", error);
      await signOut();
    }
  };

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      setIsLoading(false);
      if (user) {
        setCurrentUserFirebase(user);

        if (!user.emailVerified) return;
        else fetchAdmin(user.uid);
      }
    });
    return unsubscribe;
  }, [auth, navigate, setCurrentUserFirebase]);

  const signOut = async () => {
    await SignOutUser();
    await setCurrentUserFirebase(null);
    await setCurrentUser(null);
    await setClientId(null);
    navigate("/authentication/sign-in");
  };

  // if (isLoading) {
  //   return <div>Chargement en cours...</div>;
  // }

  const value = {
    currentUserFirebase,
    setCurrentUserFirebase,
    currentUser,
    setCurrentUser,
    clientId,
    setClientId,
    signOut,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
