import FooterWhite from "components/Footers/FooterWhite";
import ColorNavbar from "components/Navbars/ColorNavbar";
import VideoJS from "components/video/VideoPlayer";
import { useGetAllProduct, useProduct } from "hooks/produit.hook";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import CardArea from "./components/CardArea";
import PdfCard from "./components/PdfCard";
import { ESectionLesson } from "./lesson.type";

function BlogPosts() {
  let { lessonId: id } = useParams();
  const [lessonId, setLessonId] = useState(id || "");
  const { data: product, isLoading: isLoadingProduct } = useProduct(
    lessonId || ""
  );
  const { data: listeProducts, isLoading: isLoadingAllProducts } =
    useGetAllProduct(product?.subCategoryId);
  const mediaLesson = _.get(product, "documents.privateMedia") as unknown as [
    any
  ];
  const videoLesson = mediaLesson?.filter(
    (media) => media.mimetype === "video/mp4"
  )[0];

  const [activePill, setActivePill] = useState(ESectionLesson.DESCRIPTION);
  document.documentElement.classList.remove("nav-open");
  const playerRef = React.useRef(null);

  useEffect(() => {
    if (id) setLessonId(id);
  }, [id]);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: videoLesson?.signedUrl,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  const getCurrentProductIndex = () => {
    return (
      listeProducts?.products.findIndex((item) => item._id === lessonId) || 0
    );
  };

  // Fonction pour passer au produit suivant
  const handleNextProduct = () => {
    if (!listeProducts) return;
    const currentIndex = getCurrentProductIndex();
    if (currentIndex < listeProducts.products.length - 1) {
      const nextProductId = listeProducts?.products[currentIndex + 1]._id;
      setLessonId(nextProductId);
    }
  };

  // Fonction pour passer au produit précédent
  const handlePreviousProduct = () => {
    if (!listeProducts) return;
    const currentIndex = getCurrentProductIndex();
    if (currentIndex > 0) {
      const previousProductId = listeProducts?.products[currentIndex - 1]._id;
      setLessonId(previousProductId);
    }
  };

  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div className="main">
          {isLoadingProduct || isLoadingAllProducts ? (
            <div className="full-page-loader">
              <div className="uil-reload-css mr-1">
                <div />
              </div>
            </div>
          ) : (
            <div className="section section-white">
              <Container fluid>
                <Row>
                  <Col md={9} className="mb-3">
                    <h3>{product?.title}</h3>
                  </Col>
                  <Col
                    md={3}
                    className="mb-3 pt-3"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="btn-move-left btn-round mr-1"
                      color="success"
                      onClick={handlePreviousProduct}
                    >
                      <i className="nc-icon nc-minimal-left mr-1" />
                    </Button>
                    <Button
                      className="btn-move-right btn-round"
                      color="success"
                      onClick={handleNextProduct}
                    >
                      <i className="nc-icon nc-minimal-right" />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {/* Colonne de la vidéo et de la description */}
                  <Col md={8}>
                    <div>
                      <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                      />

                      {/* <div className="project-pills pt-2">
                        <Nav
                          className="nav-pills-success justify-content-center"
                          pills
                        >
                          <NavItem>
                            <NavLink
                              className={
                                activePill === ESectionLesson.DESCRIPTION
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) => {
                                setActivePill(ESectionLesson.DESCRIPTION);
                              }}
                            >
                              Description
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activePill === ESectionLesson.DOCUMENT
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) => {
                                setActivePill(ESectionLesson.DOCUMENT);
                              }}
                            >
                              Documents
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div> */}
                      <div>
                        {activePill === ESectionLesson.DESCRIPTION ? (
                          <div style={{ marginTop: 20 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: product?.description || "",
                              }}
                            />
                            {}
                          </div>
                        ) : (
                          <div>
                            <Row>
                              <PdfCard
                                title="document"
                                onClick={() => alert("document 1")}
                              />
                              <PdfCard
                                title="document 2"
                                onClick={() => alert("document 1")}
                              />
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>

                  {/* Colonne de la liste des cours, masquée sur les écrans xs à md */}
                  <Col
                    md={4}
                    className="d-none d-md-block"
                    style={{ backgroundColor: "#F7F9FA", padding: "0px" }}
                  >
                    {listeProducts?.products.map((item) => (
                      <CardArea
                        key={item._id}
                        product={item}
                        currentProduct={product?._id || ""}
                      />
                    ))}
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default BlogPosts;
