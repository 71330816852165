import React from "react";

// reactstrap components
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

// core components

function SectionPricing({ isDark }) {
  const { t } = useTranslation();
  const [pillActive, setPillActive] = React.useState("commercial");
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        <div className="pricing-5 section-gray">
          <Container>
            <Row>
              <Col md="5">
                <h2
                  className="title"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    height: "50%",
                  }}
                >
                  {t("pricing.title")}
                </h2>
                <div className="choose-plan">
                  <Nav className="nav-pills-danger" pills role="tablist">
                    {/*<NavItem>
                      <NavLink
                        className={pillActive === "personal" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillActive("personal");
                        }}
                      >
                        {t('pricing.offer0.title')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pillActive === "commercial" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillActive("commercial");
                        }}
                      >
                        {t('pricing.offer1.title')}
                      </NavLink>
                    </NavItem>*/}
                  </Nav>
                </div>
                <br />
                {/* <p className="description text-gray">
                  You have Free Unlimited Updates and Premium Support on each
                  package. You also have 20 days to request a refund if you're
                  not happy with your purchase.
                </p> */}
              </Col>
              <Col className="ml-auto" md="7">
                <TabContent className="text-center" activeTab={pillActive}>
                  {/*<TabPane tabId="personal">
                    <div className="space-top" />
                    <Row>
                      <Col md="6">
                        <Card className="card-pricing">
                          <CardBody>
                            <h6 className="card-category text-primary">
                              HTML Package
                            </h6>
                            <CardTitle tag="h1">$0</CardTitle>
                            <ul>
                              <li>
                                <b>1</b> Developer
                              </li>
                              <li>
                                <b>99+</b> Components
                              </li>
                              <li>
                                <b>HTML</b> Elements
                              </li>
                              <li>
                                <b>14</b> Page Examples
                              </li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="primary"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Free download
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="card-pricing" data-color="orange">
                          <CardBody>
                            <h6 className="card-category text-success">
                              HTML Package
                            </h6>
                            <CardTitle tag="h1">$59</CardTitle>
                            <ul>
                              <li>
                                <b>5</b> Developer
                              </li>
                              <li>
                                <b>129+</b> Components
                              </li>
                              <li>
                                <b>HTML</b> Elements
                              </li>
                              <li>
                                <b>24</b> Page Examples
                              </li>
                            </ul>
                            <Button
                              className="btn-neutral btn-round"
                              color="default"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Free download
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                      </TabPane>*/}
                  <TabPane tabId="commercial">
                    <div className="space-top" />
                    <Row style={{ justifyContent: "center" }}>
                      <Col md="6">
                        <Card className="card-pricing">
                          <CardBody>
                            <h6 className="card-category text-warning">
                              {t("pricing.offer0.title")}
                            </h6>
                            <CardTitle tag="h1">
                              {t("pricing.offer0.price")}€
                            </CardTitle>
                            {t("pricing.offer0.header")}
                            <ul>
                              {/* <li>{t("pricing.offer0.include")}</li> */}
                              <li>{t("pricing.offer0.support")}</li>
                              <li>{t("pricing.offer0.bonus")}</li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="warning"
                              href="/purchase"
                            >
                              {t("pricing.offer0.button")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      {/*_<Col md="6">
                        <Card className="card-pricing" data-color="orange">
                          <CardBody>
                            <h6 className="card-category text-success">
                              HTML Package
                            </h6>
                            <CardTitle tag="h1">$359</CardTitle>
                            <ul>
                              <li>
                                <b>15</b> Developer
                              </li>
                              <li>
                                <b>229+</b> Components
                              </li>
                              <li>
                                <b>HTML</b> Elements
                              </li>
                              <li>
                                <b>44</b> Page Examples
                              </li>
                            </ul>
                            <Button
                              className="btn-neutral btn-round"
                              color="default"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Free download
                            </Button>
                          </CardBody>
                        </Card>
                    </Col>*/}
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 5 ********* */}
      </div>
    </>
  );
}

export default SectionPricing;
