import React, { useState } from "react";
import truncateText from "utils/truncateText";

interface RectangleProps {
  title: string;
  onClick: () => void;
}

const Rectangle: React.FC<RectangleProps> = ({ title, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const style: React.CSSProperties = {
    margin: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100px",
    height: "120px",
    backgroundColor: isHovered ? "#e0e0e0" : "#f2f2f2", // Change de couleur au survol
    borderRadius: "10px",
    cursor: "pointer",
    padding: "10px",
    boxSizing: "border-box",
    transition: "background-color 0.3s ease",
    overflow: "hidden",
  };

  return (
    <div
      style={style}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true"></i>
      <p style={{ margin: "10px 0 0 0" }}>{truncateText(title, 15)}</p>
    </div>
  );
};

export default Rectangle;
