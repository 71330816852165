import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "App";
import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-kit.scss";
import "config/firebase.config";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth-context/auth.context";
import "./i18n";

import "config/firebase.config";
const rootElement =
  document.getElementById("root") ??
  (() => {
    throw new Error("Root element not found");
  })();
const root = ReactDOM.createRoot(rootElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
