/*eslint-disable*/

// reactstrap components
import { useGetAllProduct } from "hooks/produit.hook";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

// core components

function SectionCourses({ isDark }) {
  const { t, i18n } = useTranslation();
  const { data: products, isLoading: isLoadingProducts } = useGetAllProduct();

  return (
    <>
      <div
        className={`section section-project cd-section ${
          isDark ? "section-dark" : ""
        }`}
        id="courses"
      >
        <div className="courses-1" id="courses-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <div className="space-top" />
                <h2 className="title">{t("courses.title")}</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p className="">{t("courses.description")}</p>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <CardBody>
                  <h6 className="card-category text-danger">
                    {t("courses.agribusiness.title")}
                  </h6>
                  <p className="card-description">
                    {t("courses.agribusiness.description")}
                  </p>
                </CardBody>
              </Col>
              <Col md="6">
                <CardBody>
                  <h6 className="card-category text-danger">
                    {t("courses.leadership.title")}
                  </h6>
                  <p className="card-description">
                    {t("courses.leadership.description")}
                  </p>
                </CardBody>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      {t("courses.methodology.card1.title")}
                    </h4>
                    <p>{t("courses.methodology.card1.description")}</p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-palette" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      {t("courses.methodology.card2.title")}
                    </h4>
                    <p>{t("courses.methodology.card2.description")}</p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      {t("courses.methodology.card3.title")}
                    </h4>
                    <p>{t("courses.methodology.card3.description")}</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              {isLoadingProducts ? (
                <div className="full-page-loader">
                  <div className="uil-reload-css mr-1">
                    <div />
                  </div>
                </div>
              ) : (
                products.products &&
                products?.products.slice(0, 6).map((product, idx) => (
                  <Col md="4">
                    <Card className="card-profile card-plain">
                      <CardImg top tag="div">
                        <a href={"/list-trainings"}>
                          <img
                            alt="..."
                            className="img"
                            src={product.media_doc.url}
                          />
                        </a>
                      </CardImg>
                      <CardBody>
                        <h6 className="card-category">
                          {t(`courses.card${idx}.badge`)}
                        </h6>
                        <CardTitle tag="h4">{product.title}</CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionCourses;
