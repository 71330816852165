import classnames from "classnames";
import { AuthContext } from "context/auth-context/auth.context";
import Headroom from "headroom.js";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import ukflag from "../../assets/img/flags/uk.png";
import frFlag from "../../assets/img/flags/fr.png";
import logo from "../../assets/img/favicon.png";

function ColorNavbar() {
  const options = [
    {
      value: "fr",
      label: (
        // <div>
        <img src={frFlag} width="20" alt="" />
        // </div>
      ),
    },
    {
      value: "en",
      label: (
        // <div>
        <img src={ukflag} width="20" alt="" />
        // </div>
      ),
    }, //en
  ];

  const { t, i18n } = useTranslation();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { currentUserFirebase, signOut } = useContext(AuthContext);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [lang, setLang] = React.useState(options[0].value);
  const [langLabel, setLangLabel] = React.useState(options[0].label);

  function handlclick(n) {
    setLangLabel(options[n].label);
    setLang(options[n].value);
    i18n.changeLanguage(options[n].value);
  }

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        // className={classnames("fixed-top", navbarColor)}
        className="fixed-top"
        expand="lg"
        id="navbar-main"
        style={{ backgroundColor: "#fff" }}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              to="/presentation"
              style={{ color: "#f9cd00" }}
              tag={Link}
            >
              <img
                src={logo}
                alt="JeufZone Logo"
                style={{ height: "40px", marginRight: "10px" }} // Ajustez la taille et les marges selon vos besoins
              />
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span
                className="navbar-toggler-bar bar1"
                style={{ background: "#000" }}
              />
              <span
                className="navbar-toggler-bar bar2"
                style={{ background: "#000" }}
              />
              <span
                className="navbar-toggler-bar bar3"
                style={{ background: "#000" }}
              />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink active href="/#about-us" style={{ color: "#f9cd00" }}>
                  {" "}
                  {t("nav.aboutUs")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active href="/#courses" style={{ color: "#f9cd00" }}>
                  {t("nav.courses")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active href="/#" style={{ color: "#f9cd00" }}>
                  {t("nav.eBook")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active
                  href="/#testimonials"
                  style={{ color: "#f9cd00" }}
                >
                  {t("nav.testimonials")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active
                  href="/#contact-us"
                  style={{ color: "#f9cd00" }}
                >
                  {t("nav.contact")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active href="/#pricing" style={{ color: "#f9cd00" }}>
                  {t("nav.shop")}
                </NavLink>
              </NavItem>

              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  className=" bg-transparent btn-outline-secondary border-0 "
                  id="lng-dropdown"
                >
                  {langLabel}
                </DropdownToggle>
                {/* <Button className="bg-transparent  border-0 p-1">
                  {langLabel}
                </Button>
                <Dropdown.Toggle
                  split
                  className="bg-transparent btn-outline-secondary border-0 p-1"
                  id="dropdown-split-lng"
                /> */}
                <DropdownMenu>
                  <DropdownItem onClick={() => handlclick(0)}>
                    <img src={frFlag} width="20" alt="" /> {options[0].value}
                  </DropdownItem>
                  <DropdownItem onClick={() => handlclick(1)}>
                    <img src={ukflag} width="20" alt="" /> {options[1].value}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {!currentUserFirebase || isEmpty(currentUserFirebase) ? (
                <NavItem>
                  <Button
                    className="btn-round"
                    color="danger"
                    href="/authentication/sign-in"
                    target="_blank"
                  >
                    Login
                  </Button>
                </NavItem>
              ) : (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    data-toggle="dropdown"
                    height="30"
                    width="30"
                    tag={NavLink}
                  >
                    <div className="profile-photo-small">
                      <img
                        alt="..."
                        className="img-circle img-responsive img-no-padding"
                        src={require("assets/img/faces/accunte.png")}
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-info" right>
                    {/* <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Mon compte
                    </DropdownItem>
                    <DropdownItem divider /> */}
                    <DropdownItem to="/list-trainings" tag={Link}>
                      Training
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="#pablo" onClick={signOut}>
                      Sign out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
