import api from "config/axios.config";
import { IProduct, IProductPagination } from "../types/produit.type";
import { IResponseError } from "types/response.type";
import { IQuery } from "types/query.type";

export const getProduct = (id: string): Promise<IProduct | IResponseError> => {
  return api.get(`clients/${process.env.REACT_APP_CLIENT_ID}/product/${id}`);
};

export const getProductPagination = ({
  page,
  limit,
  select,
  include,
  where,
}: IQuery): Promise<IProductPagination> => {
  return api.get(
    `clients/${
      process.env.REACT_APP_CLIENT_ID
    }/product/aggregate?select=${select}&include=${include}&limit=${limit}&page=${page}${
      where ? `&where=${where}` : ""
    }`
  );
};
