import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import { BuyProduct, Subscription } from "./design.type";
import {
  buyProducts,
  getOneSubscription,
  getOrderUser,
} from "./purchase.service";

export enum KeyQueryPurchase {
  getOneSubscription = "getOneSubscription",
  getOrderUser = "getOrderUser",
}

export const useGetOneSubscription = (
  id: string
): UseQueryResult<Subscription> => {
  return useQuery({
    queryKey: [KeyQueryPurchase.getOneSubscription, id],
    queryFn: () => {
      if (!id) return null;

      return getOneSubscription(id);
    },
  });
};

export const useGetOrderUser = (): UseQueryResult<any> => {
  return useQuery({
    queryKey: [KeyQueryPurchase.getOrderUser],
    queryFn: () => getOrderUser(),
  });
};

export const useBuyProducts = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (products: BuyProduct[]) => buyProducts(products),
    onSuccess: (result) => {
      // queryClient.setQueryData(
      //   [KeyQueryDesign.getById, clientId, SELECT_DESIGN],
      //   result
      // );
    },
  });
};
