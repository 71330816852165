import ColorNavbar from "components/Navbars/ColorNavbar";
import { useGetAllProduct } from "hooks/produit.hook";
import { useGetAllSubCategory } from "hooks/sub-category.hook";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap";
import FooterWhite from "../../components/Footers/FooterWhite";

// core components
function ListTrainings() {
  const { data: categories, isLoading: isLoadingCategories } =
    useGetAllSubCategory();
  const { data: products, isLoading: isLoadingProducts } = useGetAllProduct();
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });

  return (
    <>
      {/* <ColorNavbar /> */}
      <ColorNavbar />

      <div className="wrapper">
        <div className="blog-2 section section-gray">
          {isLoadingCategories || isLoadingProducts ? (
            <div className="full-page-loader">
              <div className="uil-reload-css mr-1">
                <div />
              </div>
            </div>
          ) : (
            <>
              {categories &&
                categories.map((category) => {
                  const productFilter =
                    products &&
                    products?.products.filter(
                      (item) => item.subCategoryId === category._id
                    );
                  return (
                    !!productFilter?.length && (
                      <Container>
                        <Row>
                          <Col className="ml-auto mr-auto" md="10">
                            <h4 style={{ marginBottom: 10 }}>
                              {category.name}
                            </h4>
                            <br />
                            <Row>
                              {productFilter.map((product) => {
                                return (
                                  <Col
                                    key={product._id}
                                    md="3"
                                    style={{ marginBottom: 20 }}
                                  >
                                    <Card className="card-blog">
                                      <Link to={`/lesson/${product._id}`}>
                                        <div className="card-image">
                                          <img
                                            alt="..."
                                            className="img img-raised"
                                            src={product.media_doc?.url}
                                          />
                                        </div>
                                        <CardBody>
                                          <h6
                                            id={`product-title-${product._id}`}
                                            className="card-category text-success text-truncate"
                                          >
                                            {product.title}
                                          </h6>

                                          {/* <p className="card-description">
                                          {product.description} <br />
                                        </p>
                                        <hr /> */}
                                          <CardFooter>
                                            {/* <div className="author">
                                      <div className="stats">
                                        <i className="fa fa-book" /> 4 lessons
                                      </div>
                                    </div>
                                    <div className="stats">
                                      <i className="fa fa-clock-o" /> 5 hours
                                    </div> */}
                                          </CardFooter>
                                        </CardBody>
                                      </Link>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    )
                  );
                })}
            </>
          )}
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default ListTrainings;
