import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import ColorNavbar from "../../components/Navbars/ColorNavbar.js";
import { sendVerificationEmail } from "../../services/firebase.service";

function VerifyEmail() {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  const handleSendEmail = async () => {
    try {
      setIsDisabled(true);
      await sendVerificationEmail();
      setTimeout(() => {
        setIsDisabled(false);
      }, 6000); // 60 secondes
    } catch (error: any) {
      console.error("error:", error);
    }
  };

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: "url(" + require("assets/img/bg-login.png") + ")",
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                <Card className="card-register">
                  <CardTitle tag="h3" style={{ marginBottom: 12 }}>
                    Email verification
                  </CardTitle>
                  <CardSubtitle>
                    You will receive an e-mail within 60 seconds.
                  </CardSubtitle>
                  <Button
                    block
                    className="btn-round"
                    style={{ backgroundColor: "#117450" }}
                    type="submit"
                    onClick={() => window.location.reload()}
                  >
                    Reload page
                  </Button>
                  <Button
                    block
                    className="btn-round"
                    style={{ backgroundColor: "#117450" }}
                    type="submit"
                    onClick={handleSendEmail}
                    disabled={isDisabled}
                  >
                    resend email
                  </Button>
                </Card>
              </Col>
            </Row>
            <div className="demo-footer text-center">
              <h6>
                © {new Date().getFullYear()}, made with{" "}
                <i className="fa fa-heart heart" /> by Hopelapp
              </h6>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
