const getCurrencySymbol = (currencyCode: string) => {
  const currencySymbols: { [key: string]: string } = {
    EUR: "€",
    USD: "$",
    CAD: "C$",
  };

  const code = currencyCode.toUpperCase();

  return currencySymbols[code] || "€";
};

export default getCurrencySymbol;
