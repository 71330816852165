import axios from "axios";
import { getAuthToken } from "services/firebase.service";

const api = axios.create({
  baseURL: process.env.REACT_APP_HOPELAPP_API,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();

    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) return error.response.data;
    else if (error.request) throw new Error("Unable to make request");
    else throw error;
  }
);

export default api;
