import FooterWhite from "components/Footers/FooterWhite";
import ProductPageHeader from "components/Headers/ProductPageHeader.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import { AuthContext } from "context/auth-context/auth.context";
import { useGetClient } from "hooks/client.hook";
import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import { SELECT_SETTINGS } from "types/client.type";
import getCurrencySymbol from "utils/currencySymbols";
import {
  useBuyProducts,
  useGetOneSubscription,
  useGetOrderUser,
} from "./purchase.hook";

const findProductInBasket = (orders: any, productId: string) => {
  return (
    Array.isArray(orders) &&
    orders?.some((order: any) =>
      order?.basket?.some((item: any) => item.productId === productId)
    )
  );
};

const modules = [
  {
    title: "Module 1 : Généralités sur l’agriculture",
    description:
      "Plongez au coeur d es fondamentaux de l'agriculture dans notre module complet. Ce module vous fournira une vue d'ensemble approfondie de ce secteur crucial. Découvrez les principes fondamentaux de la science agricole, explorez les dernières technologies et pratiques durables, et apprenez à relever les défis contemporains de la sécurité alimentaire. Que vous soyez un novice cherchant à comprendre les bases ou un professionnel de l'agriculture désireux d'approfondir vos connaissances, ce module vous offrira une perspective complète sur le monde dynamique de l'agriculture.",
  },
  {
    title: "Module 2 : Aménagement d’un terrain agricole",
    description:
      "Découvrez les secrets de l'aménagement d'un terrain agricole dans notre module spécialisé. De la délimitation initiale à la phase finale d'aménagement, ce cours vous guidera à travers chaque étape du processus. Apprenez les techniques efficaces de nettoyage, de défrichage et de préparation du sol pour maximiser la productivité de votre exploitation. Que vous soyez un agriculteur débutant ou expérimenté, ce module vous fournira les compétences nécessaires pour transformer votre terrain en un environnement agricole prospère et durable.",
  },
  {
    title: "Module 3 : Maraîchage",
    description:
      "Explorez les subtilités du maraîchage dans notre module spécialisé. De la conception des planches à la transplantation et au semis, ce cours détaillé vous apprendra les techniques essentielles pour cultiver des légumes frais et savoureux de manière efficace et durable. Que vous soyez un novice cherchant à démarrer votre propre jardin ou un professionnel agricole cherchant à perfectionner vos compétences, ce module vous fournira les connaissances pratiques nécessaires pour réussir dans le monde exigeant du maraîchage.",
  },
  {
    title: "Module 4 : Arbori culture",
    description:
      "Explorez l'art de l'arboriculture dans notre module spécialisé. De la sélection des arbres adaptés à votre environnement à la gestion des vergers, ce cours complet vous guidera à travers chaque aspect de la culture des arbres fruitiers et ornementaux. Apprenez les techniques de plantation, de taille et de soins nécessaires pour assurer une croissance saine et une production abondante. Que vous soyez un passionné de jardinage ou un professionnel de l'agriculture, ce module vous fournira les compétences nécessaires pour créer et entretenir des arbres robustes et florissants.",
  },
  {
    title: "Module 5 : Gestion de la ferme",
    description:
      "Voyagez dans le monde complexe de la gestion de la ferme avec notre module dédié. De la planification stratégique à la gestion des ressources humaines et financières, ce cours exhaustif vous fournira les outils et les connaissances nécessaires pour gérer efficace ment une exploitation agricole prospère. Apprenez à élaborer des plans d'affaires solides, à optimiser les opérations quotidiennes et à prendre des décisions éclairées pour maximiser la rentabilité et la durabilité de votre ferme. Que vous soyez un agriculteur en herbe ou un vétéran de l'industrie, ce module vous aidera à relever les défis de la gestion agricole moderne avec confiance et succès.",
  },
  {
    title: "Module : 6 Élevage",
    description:
      "Sondez les principes fondamentaux de l'élevage dans notre module spécialisé. De la sélection des animaux à la gestion de la santé et de la nutrition, ce cours approfondi vous fournira les connaissances et les compétences nécessaires pour élever des animaux en bonne santé et productifs. Apprenez les meilleures pratiques pour la reproduction, le logement et les soins vétérinaires afin d'optimiser le bien être de vos animaux et la rentabilité de votre exploitation. Que vous soyez un éleveur débutant ou expérimenté, ce module vous guidera à travers les défis et les opportunités de l'élevage moderne, vous préparant à réussir dans ce secteur crucial de l'agriculture.",
  },
  {
    title: "Module 7 : Aviculture ",
    description:
      "Sillonnez les secrets de l'aviculture dans notre module spécialisé. De la sélection des races à la gestion des installations et à la santé des volailles, ce cours détaillé vous fournira les connaissances et les compétences nécessaires pour élever des volailles saine s et productives. Apprenez les meilleures pratiques pour la gestion des poulaillers, la nutrition des volailles et la prévention des maladies afin de maximiser la qualité et la quantité de vos produits avicoles. Que vous soyez un amateur de volai lle ou un professionnel de l'agriculture, ce module vous préparera à réussir dans le monde exigeant de l'aviculture, en vous fournissant les outils nécessaires pour prospérer dans cette industrie cruciale de l'agriculture.",
  },
  {
    title: "Module 8 : Leadership (bientôt disponible)",
    description:
      "Arpentez les fondements du leadership dans le contexte agricole avec notre module spécialisé. De la communication efficace à la prise de décision stratégique, ce cours approfondi vous fournira les compétences et les stratégies nécessaires pour diriger avec succès une équipe agricole. Apprenez à inspirer, motiver et guider vos collaborateurs pour atteindre des objectifs communs, tout en naviguant dans les défis spécifiques de l'industrie agricole. Que vous soyez un agriculteur souhaitant améliorer vos compét ences en leadership ou un professionnel cherchant à diriger une équipe agricole avec confiance, ce module vous aidera à développer les qualités de leadership nécessaires pour prospérer dans le monde dynamique de l'agriculture.",
  },
  {
    title: "Module 9 : Structuration de projets agricoles (bientôt disponible)",
    description:
      "Immergez dans le processus de structuration d'un projet agricole avec notre module spécialisé. De la conception initiale à l'exécution et à la gestion, ce cours approfondi vous guidera à travers chaque étape critique du développement d'un projet agricole réussi. A pprenez à élaborer des plans d'affaires solides, à évaluer les risques et les opportunités, et à mobiliser les ressources nécessaires pour concrétiser votre vision agricole. Que vous envisagiez de lancer une nouvelle entreprise agricole ou d'expans er une exploitation existante, ce module vous fournira les connaissances et les compétences nécessaires pour structurer et gérer votre projet agricole avec succès.",
  },
];

function Purchase() {
  const buyProducts = useBuyProducts();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  // const { data: products, isLoading: isLoadingProducts } = useGetAllProduct();

  const { currentUser } = useContext(AuthContext);
  const subscriptionId = process.env.REACT_APP_SUBSCRIPTION_ID as string;
  const { data: subscription, isLoading: isLoadingSubscription } =
    useGetOneSubscription(subscriptionId);
  const { data: client, isLoading: isLoadingClient } =
    useGetClient(SELECT_SETTINGS);
  const { data: orders } = useGetOrderUser();
  const urlBuy = !currentUser ? "/authentication/sign-in" : null;
  const isProductInBasket = findProductInBasket(orders, subscriptionId);

  React.useEffect(() => {
    document.body.classList.add("product-page");
    return function cleanup() {
      document.body.classList.remove("product-page");
    };
  });

  const toggleCollapse = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleSubmit = async () => {
    if (urlBuy) {
      window.location.href = urlBuy;
      return;
    }
    setLoading(true);
    try {
      const checkoutSession = await buyProducts.mutateAsync([
        { id: subscription?._id as string, quantity: 1 },
      ]);
      if (checkoutSession.url) {
        window.location.href = checkoutSession.url;
      } else {
        console.error("No URL received from checkout session");
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ColorNavbar />
      <ProductPageHeader />
      <div className="main">
        {isLoadingSubscription || isLoadingClient ? (
          <div className="full-page-loader">
            <div className="uil-reload-css mr-1">
              <div />
            </div>
          </div>
        ) : (
          <div className="section">
            <Container>
              <Row className="title-row">
                <Col md="2">
                  <h4 className="shop">Produit</h4>
                </Col>
              </Row>
              <Row>
                <Col md="7" sm="6">
                  <img
                    alt="..."
                    className="img-rounded img-responsive"
                    src={require("assets/img/coursComplet.jpeg")}
                  />
                  {/* end carousel */}
                </Col>
                <Col md="5" sm="6">
                  <h2>Package Complet de Cours</h2>
                  <h4 className="price">
                    <strong>
                      {`${subscription?.price} ${getCurrencySymbol(
                        client?.settings?.currency || "EUR"
                      )} `}
                    </strong>
                  </h4>
                  <hr />
                  <p>
                    Plongez dans un monde de connaissances agricoles et
                    d'entrepreneuriat avec notre pack de cours complet.
                  </p>

                  <hr />
                  <Row>
                    <Col className="offset-md-5" md="7" sm="8">
                      <Button
                        block
                        className="btn-round"
                        color="danger"
                        onClick={() =>
                          !loading && !isProductInBasket && handleSubmit()
                        }
                        disabled={loading || isProductInBasket}
                      >
                        {loading ? (
                          <>
                            <div className="uil-reload-css reload-small mr-1">
                              <div />
                            </div>
                            Chargement
                          </>
                        ) : (
                          <>
                            Acheter
                            <i className="fa fa-chevron-right" />
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container>
              <div className="faq">
                <h4>Ce que vous apprendrez</h4>
                <br />
                <div id="acordeon">
                  <div
                    aria-multiselectable={true}
                    id="accordion"
                    role="tablist"
                  >
                    <Card className="no-transition">
                      {modules.map((product, index) => (
                        <React.Fragment key={index}>
                          <CardHeader
                            className="card-collapse"
                            id={`heading${index}`}
                            role="tab"
                          >
                            <h5 className="mb-0 panel-title">
                              <a
                                aria-expanded={activeIndex === index}
                                href={`#collapse${index}`}
                                id={`collapse${index}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleCollapse(index);
                                }}
                              >
                                {product.title}
                                <i className="nc-icon nc-minimal-down" />
                              </a>
                            </h5>
                          </CardHeader>
                          <UncontrolledCollapse
                            aria-labelledby={`heading${index}`}
                            role="tabpanel"
                            toggler={`#collapse${index}`}
                            isOpen={activeIndex === index}
                          >
                            <CardBody>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: product?.description || "",
                                }}
                              />
                            </CardBody>
                          </UncontrolledCollapse>
                        </React.Fragment>
                      ))}
                    </Card>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        )}
      </div>
      <FooterWhite />
    </>
  );
}

export default Purchase;
