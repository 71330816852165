import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import ColorNavbar from "../../components/Navbars/ColorNavbar.js";
import { signInUser } from "../../services/firebase.service";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

function SignIn() {
  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    try {
      const { email, password } = values;
      await signInUser(email, password);
    } catch (error: any) {
      alert(error);
    }
  };

  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: "url(" + require("assets/img/bg-login.png") + ")",
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                <Card className="card-register">
                  <CardTitle tag="h3">Welcome</CardTitle>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, errors, touched, isValid, dirty }) => (
                      <Form id="signIn-form">
                        <Label for="email">Email</Label>
                        <Field name="email" type="email" as={Input} />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />

                        <Label for="password">Password</Label>
                        <Field name="password" type="password" as={Input} />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />

                        <Button
                          block
                          className="btn-round"
                          style={{ backgroundColor: "#117450" }}
                          disabled={isSubmitting || !isValid || !dirty}
                          type="submit"
                        >
                          Sign In
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <div className="forgot">
                    <Button
                      className="btn-link"
                      style={{ color: "#117450" }}
                      href="/authentication/sign-up"
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div className="forgot">
                    <Button
                      className="btn-link"
                      style={{ color: "#117450" }}
                      href="/authentication/reset-password"
                    >
                      Forgot password?
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
            <div className="demo-footer text-center">
              <h6>
                © {new Date().getFullYear()}, made with{" "}
                <i className="fa fa-heart heart" /> by Hopelapp
              </h6>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SignIn;
