import React from "react";

import SectionAboutUs from "views/sections-sections/SectionAboutUs";
import SectionContactUs from "views/sections-sections/SectionContactUs";
import SectionCourses from "views/sections-sections/SectionCourses";
import SectionPricing from "views/sections-sections/SectionPricing";
import SectionTestimonials from "views/sections-sections/SectionTestimonials";
import FooterWhite from "../../components/Footers/FooterWhite";
import ColorNavbar from "../../components/Navbars/ColorNavbar";
import HeaderComponenent from "./components/HeaderComponenent";

import { useTranslation } from "react-i18next";

// core components
function Index() {
  const { t } = useTranslation();

  document?.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document?.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document?.body.classList.remove("index-page");
    };
  });

  return (
    <>
      <ColorNavbar />
      <HeaderComponenent />
      <div className="wrapper">
        <SectionAboutUs isDark={false} />
        <SectionCourses isDark={false} />
        <SectionPricing isDark={false} />
        <SectionTestimonials isDark={false} />
        <SectionContactUs isDark={false} />
      </div>
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#headers"
              onClick={(e) => {
                e.preventDefault();
                document?.getElementById("about-us")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t("nav.aboutUs")}</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#features"
              onClick={(e) => {
                e.preventDefault();
                document?.getElementById("courses")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t("nav.courses")}</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#pricing"
              onClick={(e) => {
                e.preventDefault();
                document?.getElementById("pricing")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t("nav.shop")}</span>
            </a>
          </li>
          <li>
            <a
              data-number="7"
              href="#testimonials"
              onClick={(e) => {
                e.preventDefault();
                document?.getElementById("testimonials")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t("nav.testimonials")}</span>
            </a>
          </li>
          <li>
            <a
              data-number="8"
              href="#contact-us"
              onClick={(e) => {
                e.preventDefault();
                document?.getElementById("contact-us")?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t("nav.contact")}</span>
            </a>
          </li>
        </ul>
      </nav>
      <FooterWhite />
    </>
  );
}

export default Index;
