import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getClients } from "services/client.service";
import { IClient } from "types/client.type";

export enum KeyQueryClient {
  getClients = "getClients",
}

export const useGetClient = (select?: string): UseQueryResult<IClient> => {
  return useQuery({
    queryKey: [KeyQueryClient.getClients, select],
    queryFn: () => getClients(select),
  });
};

// export const useUpdateGetAdminByUID = (uid: string) => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: (uid: string) => findAdminByUID(uid),
//     onSuccess: async (result) => {
//       queryClient.setQueryData([KeyQueryAdmin.getByUID, result.uid], () => result);
//     },
//   });
// };
