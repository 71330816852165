import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IProduct } from "types/produit.type";

type Props = {
  product: IProduct;
  currentProduct: string;
};
const CardArea = ({ product, currentProduct }: Props) => {
  const [hover, setHover] = useState(false); // État pour gérer le survol

  // Utilisez React.CSSProperties pour garantir la compatibilité des types
  const style: React.CSSProperties = {
    paddingTop: "2px",
    display: "flex",
    flexDirection: "column", // Correctement typé comme 'column'
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
    backgroundColor: hover ? "#DCDCDC" : "#f0f0f0", // Changement de couleur sur le survol
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderBottom: "1px solid #B4B4B4",
    color: currentProduct === product._id ? "green" : "gray",
  };

  return (
    <Link to={`/lesson/${product._id}`}>
      <div
        style={style}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <h5 style={{ fontWeight: "bold", margin: "0", textAlign: "center" }}>
          {product.title}
        </h5>
        {/* <p style={{ marginTop: "4px" }}>2h</p> // Description sous le titre */}
      </div>
    </Link>
  );
};

export default CardArea;
