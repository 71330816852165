// reactstrap components
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

// core components

function SectionAboutUs({ isDark }) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`section secion-blog cd-section ${
          isDark ? "section-dark" : ""
        }`}
        id="about-us"
      >
        <div className="about-us1" id="about-us1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h2 className="title">{t("about-us.title")}</h2>
                <br />
                <Card
                  className="card-plain card-blog"
                  style={{ marginBottom: "10px" }}
                >
                  <Row style={{ alignItems: "center" }}>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/sections/about-us/1.jpeg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        {/* <CardTitle tag="h3">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t('about-us.card0.title')}
                          </a>
                        </CardTitle> */}
                        <p className="">{t("about-us.card0.paragraph")}</p>
                        <p className="">{t("about-us.card0.paragraph2")}</p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card
                  className="card-plain card-blog"
                  style={{ marginBottom: "6px" }}
                >
                  <Row>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            {t("about-us.card1.title")}
                          </a>
                        </CardTitle>
                        <p className="">{t("about-us.card1.paragraph")}</p>
                      </CardBody>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/sections/about-us/2.jpeg")}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <CardTitle tag="h3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {t("about-us.card3.title")}
                    </a>
                  </CardTitle>
                  <Row style={{ alignItems: "center" }}>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/sections/about-us/3.jpeg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <p className="">{t("about-us.card3.paragraph1")}</p>
                        <p className="">{t("about-us.card3.paragraph2")}</p>
                        <p className="">{t("about-us.card3.paragraph3")}</p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionAboutUs;
