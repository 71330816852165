import { isEmpty } from "lodash";
import { useContext } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../auth-context/auth.context";
import { useProduct } from "hooks/produit.hook";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { lessonId } = useParams();
  const { data: product, isLoading: isLoadingProduct } = useProduct(
    lessonId || ""
  );

  const { currentUserFirebase, isLoading, currentUser } =
    useContext(AuthContext);
  const location = useLocation();

  if (isLoading || isLoadingProduct) {
    return (
      <div className="full-page-loader">
        <div className="uil-reload-css mr-1">
          <div />
        </div>
      </div>
    );
  }

  if (currentUserFirebase && !currentUserFirebase?.emailVerified) {
    return (
      <Navigate
        to="/authentication/verify-email"
        state={{ from: location }}
        replace
      />
    );
  }

  if (product?.statusCode === 403 || !product) {
    // si pas abonner le redirige vers la page ou il faut s'abonner
    return <Navigate to="/purchase" state={{ from: location }} replace />;
  }

  if (!currentUserFirebase || isEmpty(currentUserFirebase)) {
    return (
      <Navigate
        to="/authentication/sign-in"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export default RequireAuth;
