import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getProduct, getProductPagination } from "services/produit.service";
import { IProduct } from "types/produit.type";
import { number } from "yup";

export enum KeyQueryProduit {
  getProduct = "getProduct",
  getAllProductsPagination = "getAllProductsPagination",
}

export const useProduct = (
  id: string
): UseQueryResult<IProduct> => {
  return useQuery({
    queryKey: [KeyQueryProduit.getProduct, id],
    queryFn: () => getProduct(id),
  });
};

export const useGetAllProduct = (subCategoryId?: string) => {
  return useQuery({
    queryKey: [KeyQueryProduit.getProduct, subCategoryId],
    queryFn: () =>
      getProductPagination({
        page: 1,
        limit: 1000,
        select: "title,mediaId,subCategoryId,description",
        where: subCategoryId
          ? `{"subCategoryId": "${subCategoryId}"}`
          : undefined,
        include: "mediaId",
      }),
  });
};
