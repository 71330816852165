import api from "config/axios.config";
import { IUser } from "types/user.type";
import { BuyProduct } from "./design.type";
import { CheckoutSession } from "types/stripe.type";

export const getOneSubscription = (id: string): Promise<IUser> => {
  return api.get(
    `clients/${process.env.REACT_APP_CLIENT_ID}/subscription/${id}`
  );
};

export const buyProducts = (
  products: BuyProduct[]
): Promise<CheckoutSession> => {
  return api.post(`clients/${process.env.REACT_APP_CLIENT_ID}/payments`, {
    products,
  });
};

export const getOrderUser = (): Promise<any> => {
  return api.get(`clients/${process.env.REACT_APP_CLIENT_ID}/order/user`);
};
