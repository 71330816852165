export interface IClient {
  readonly _id?: string;
  name?: string;
  code?: string;
  logo?: string;
  email?: string;
  phoneAppName?: string;
  shortDesc?: string;
  longDesc?: string;
  keywords?: string;
  menu?: [];
  description?: string;
  socials?: ISocials;
  settings?: ISettings;
  address?: IAddressClient;
  readonly createdAt?: string;
  updatedAt?: Date;
}

export interface ICreateClient {
  name: string;
  email: string;
  activity: string;
  description: string;
}

export const SELECT_CUSTOMERS =
  "id,name,logo,statusClient,statusApp,createdAt,code";
export const SELECT_INFORMATION =
  "id,name,logo,description,keywords,design,socials,address,settings";
export const SELECT_FEATURES = "features";
export const SELECT_SETTINGS = "settings.currency";
export const SELECT_DESIGN = "name,logo,design,menu";

export enum StatusClient {
  PROSPECT = "Prospect",
  CLIENT = "Client",
}

export enum StatusApp {
  TEST = "Test",
  STAGING = "Staging",
  DEPLOYMENT = "Deployment",
}

export type DataFeatures = {
  enabled: boolean;
  key?: string;
};

export interface ISocials {
  webSite: string;
  twitter: string;
  facebook: string;
  instagram: string;
  linkedin: string;
}
export interface ISettings {
  currency?: ECurrency;
  statusApp?: EStatusApp;
  statusClient?: EStatusClient;
  statusEnv?: EStatusEnv;
}

export interface IAddressClient {
  address: string;
  link: string;
}

export enum EStatusClient {
  PROSPECT = "Prospect",
  CLIENT = "Client",
}

export enum EStatusEnv {
  TEST = "Test",
  PRODUCTION = "Production",
}

export enum EStatusApp {
  BLOCKED = "blocked", // Blocked by superAdmin essentially
  DEACTIVATE = "deactivate",
  MAINTENANCE = "maintenance",
  DELETE = "delete",
  ACTIVATE = "activate",
}

export enum ECurrency {
  EUR = "EUR",
  CAD = "CAD",
}
